import React from 'react';
import { SortableHandle } from 'react-sortable-hoc';
import { useSpring, animated } from 'react-spring';
import { v4 as uuidv4 } from 'uuid';

import { genericErrorMessage } from 'components/helpers/errors';

import usePrevious from 'components/hooks/usePrevious';

import AvailableSequenceList from 'containers/AvailableSequenceList';
import AddButton from 'components/buttons/AddButton';
import DragIcon from '-!svg-react-loader?name=DragIcon!icons/ic-drag.svg';
import SubmittableField from 'components/application/SubmittableField';
import RowBar from 'components/application/RowBar';

const DragHandle = SortableHandle((_props) => <div className={'drag-handle drag-handle--padded m-l-3 m-r-3'}><DragIcon className={`drag-icon [&_path]:tw-fill-grey-300`} width={8} height={18} /></div>);

export default function ProjectTrade(props) {
  const {
    dispatch,
    containerRef,
    addError,
    projectTradeId,
    tradeName,
    isOrdering,
    newSequenceText,
    isAddingSequence,
    newSequenceNameKey
  } = props;

  const modifiers = ['enlarged', 'border-top-curved']

  if (isOrdering) modifiers.push('border-radius')

  const wasOrdering = usePrevious(isOrdering)

  const springProps = useSpring({
    transform: isOrdering ? `translateY(-150px)` : 'translateY(0px)',
    immediate: !(wasOrdering && !isOrdering)
  })

  const handleSequenceSubmit = (_event, sequenceText) => {
    axios
      .post(`/project_trades/${projectTradeId}/method_sequences`, {
        method_sequence: {
          name: sequenceText
        }
      })
      .then((response) => {
        dispatch({ type: 'CLEAR_TOGGLE_CONTENT', key: newSequenceNameKey })
        dispatch({
          type: 'ADD_METHOD_SEQUENCE',
          payload: response.data,
          projectTradeId: projectTradeId
        })
      })
      .catch(() => addError(uuidv4(), { fullMessage: genericErrorMessage }))
  }

  const handleNewSequenceNameChange = (e) => {
    dispatch({
      type: 'EDITING_CONTENT_CHANGE',
      key: `projectTradeNewSequence--${projectTradeId}`,
      value: e.target.value
    })
  }

  return (
    <div className='m-b-24'>
      <RowBar
        modifiers={modifiers}
        additionalClasses={'drag-handle-container p-l-0'}
        content={
          <React.Fragment>
            <DragHandle />
            <h3 className={`tw-text-l tw-font-semibold p-t-18 p-b-18 m-0${isOrdering ? ' truncated-text-container' : ''}`}>
              {tradeName}
            </h3>
          </React.Fragment>
        }
        actions={
          <AddButton
            text='Add task'
            disabled={false}
            customStyleClasses='mw-unset'
            onClick={() => dispatch({ type: 'UI_TOGGLE', value: true, key: newSequenceNameKey })}
          />
        }
      />

      {!isOrdering && <div className='header-bar header-bar--border tw-text-s tw-text-grey-900 tw-font-medium tw-tracking-wide tw-border-grey-100 tw-bg-grey-050'>
        Task
      </div>}
      <div className={'collapsable-list'} style={{ height: isOrdering ? 0 : 'auto', overflow: 'hidden' }}>
        <animated.div style={{ transform: springProps.transform }}>
          <div>
            {
              <React.Fragment>
                <AvailableSequenceList
                  containerRef={containerRef}
                  projectTradeId={projectTradeId}
                />
                {isAddingSequence && (
                  <div className='row-bar row-bar--border-top-none row-bar--additional-row layout--b tw-border-grey-100 tw-bg-white'>
                    <SubmittableField
                      autoFocus
                      onCancel={() => dispatch({ type: 'CLEAR_TOGGLE_CONTENT', key: newSequenceNameKey })}
                      onChange={handleNewSequenceNameChange}
                      onSubmit={handleSequenceSubmit}
                      placeholderText='Enter task name'
                      submitButtonText='Add this task'
                      value={newSequenceText}
                    />
                  </div>
                )}
              </React.Fragment>
            }
          </div>
        </animated.div>
      </div>
    </div>
  )
}
