import React, { useState, useEffect, Fragment } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { genericErrorMessage } from 'components/helpers/errors';

import CheckboxField from 'components/application/CheckboxField';
import ToggleableField from 'components/application/ToggleableField';
import ToggleableRow from 'components/application/ToggleableRow';
import AttachmentsButton from 'components/attachments/AttachmentsButton';
import AvailableAttachmentList from 'containers/AvailableAttachmentList';

export default function StepRow({ dispatch, addError, step, stepsListIsOrdering, stepFieldText, stepNameKey, isEditingToggled, areAttachmentsAllowed }) {
  const [checkBoxDisabled, setCheckBoxDisabled] = useState(false);
  const [areAttachmentsVisible, setAreAttachmentsVisible] = useState(false);

  const setAttachmentsVisible = () => setAreAttachmentsVisible(true);
  const setAttachmentsInvisible = () => setAreAttachmentsVisible(false);

  const handleStepNameChange = (e) => {
    dispatch({
      type: 'EDITING_CONTENT_CHANGE',
      key: `stepName--${step.id}`,
      value: e.target.value
    })
  }

  const handleChange = (event) => {
    setCheckBoxDisabled(true)

    axios
      .patch(`/method_steps/${step.id}`, {
        method_step: {
          selected: event.target.checked
        }
      })
      .then((response) => {
        dispatch({ type: 'UPDATE_RESOURCE', payload: response.data })
      })
      .catch(handleError)
  }

  const requestStepUpdate = (_event, fieldValue) => {
    axios
      .patch(`/method_steps/${step.id}`, {
        method_step: {
          text: fieldValue
        }
      })
      .then((response) => {
        dispatch({ type: 'UPDATE_RESOURCE', payload: response.data })
        dispatch({ type: 'CLEAR_TOGGLE_CONTENT', key: stepNameKey })
      })
      .catch(handleError)
  }

  const handleError = () => addError(uuidv4(), { fullMessage: genericErrorMessage });

  useEffect(() => {
    setCheckBoxDisabled(false)
  }, [step.attributes.selected])

  return (
    <ToggleableRow
      modifiers={['secondary', 'border-top-none']}
      isOrdering={stepsListIsOrdering}
      checkboxField={
        <CheckboxField
          disabled={checkBoxDisabled}
          height={'short'}
          value={step.id}
          name='stepSelected'
          checked={!!(step.attributes.selected)}
          onChange={handleChange}
          labelTextProps={{ className: 'tw-font-medium' }}
        />
      }
      toggleableField={
        <Fragment>
          <div className='flex'>
            <div className='flex-item flex-item--grow-but-truncate'>
              <ToggleableField
                autoFocus
                isToggled={isEditingToggled}
                onCancel={() => dispatch({ type: 'CLEAR_TOGGLE_CONTENT', key: stepNameKey })}
                onChange={handleStepNameChange}
                onSubmit={requestStepUpdate}
                onToggle={(toggleValue) => dispatch({ type: 'UI_TOGGLE', value: toggleValue, key: stepNameKey })}
                submitButtonText='Update'
                toggleableItem={
                  <div className='toggleable-field__item-wrapper'>
                    <span
                      className={`toggleable-row__text tw-border-grey-500 ${stepsListIsOrdering ? ' truncated-text-container' : ''}`}
                    >
                      {step.attributes.text}
                    </span>
                  </div>
                }
                value={stepFieldText}
              />
            </div>
            {areAttachmentsAllowed && !areAttachmentsVisible && <div className='m-l-16'><AttachmentsButton onClick={setAttachmentsVisible} /></div>}
          </div>
          {areAttachmentsAllowed && (
            <React.Fragment>
              {isEditingToggled && areAttachmentsVisible && <hr className='tw-h-px tw-bg-grey-100 tw-mt-4 tw-mb-4 tw-border-0' />}
              {!stepsListIsOrdering && <AvailableAttachmentList methodStepId={step.id} previewerLocation={'modalRoot'} areAttachmentsVisible={areAttachmentsVisible} setAttachmentsVisible={setAttachmentsVisible} setAttachmentsInvisible={setAttachmentsInvisible} />}
            </React.Fragment>
          )}
        </Fragment>
      }
    />
  )
}
