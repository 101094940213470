import React, { useState, useMemo } from 'react';
import ReactDOM from 'react-dom';
import { useSpring, animated } from 'react-spring';
import { v4 as uuidv4 } from 'uuid';

import { genericErrorMessage } from 'components/helpers/errors';

import useMeasure from 'components/hooks/useMeasure';
import usePrevious from 'components/hooks/usePrevious';

import CheckboxField from 'components/application/CheckboxField';
import ToggleableField from 'components/application/ToggleableField';
import OutlinedButton from 'components/application/buttons/OutlinedButton';
import TextButton from 'components/application/buttons/TextButton';
import FilledButton from 'components/application/buttons/FilledButton';
import ToggleableRow from 'components/application/ToggleableRow';
import RowBar from 'components/application/RowBar';
import BranchingSubNode from '-!svg-react-loader?name=BranchingSubNode!icons/branching-sub-node.svg';
import ModalBox from 'components/application/ModalBox';
import AvailableStepList from 'containers/AvailableStepList';

// todo add testing for container and see if props can be reduced
export default function SequenceRow(props) {
  const {
    dispatch,
    containerRef,
    addError,
    sequenceNameKey,
    newStepNameKey,
    projectTradeId,
    existingSequence,
    masterSequence,
    sequenceOrderingInProgress,
    stepsOrderingInProgress,
    tradeOrderingInProgress,
    isEditingSequenceToggle,
    isAddingStepName,
    sequenceFieldText,
    projectId,
    newStepText,
    stepIds
  } = props;

  // derived
  const isSelected = existingSequence ? existingSequence.attributes.selected : false;
  const sequenceTemplate = existingSequence || masterSequence;
  const [visualOptions, setVisualOptions] = useState({ confirmationModalOpen: false, checkboxDisabled: false })
  const previousTradeOrdering = usePrevious(tradeOrderingInProgress)
  const previousSequenceOrdering = usePrevious(sequenceOrderingInProgress)
  const previousStepsOrdering = usePrevious(stepsOrderingInProgress)
  const [bind, { height: viewHeight }] = useMeasure()
  const memoisedPreviousTradeOrdering = useMemo(() => { return previousTradeOrdering }, [tradeOrderingInProgress])
  const memoisedPreviousSequenceOrdering = useMemo(() => { return previousSequenceOrdering }, [sequenceOrderingInProgress])
  const memoisedPreviousStepsOrderingSorting = useMemo(() => { return previousStepsOrdering }, [stepsOrderingInProgress])

  const shouldNotTransition = (!memoisedPreviousTradeOrdering && tradeOrderingInProgress) || (!memoisedPreviousSequenceOrdering && sequenceOrderingInProgress) || (!memoisedPreviousStepsOrderingSorting && stepsOrderingInProgress)

  const springProps = useSpring({
    transform: sequenceOrderingInProgress || tradeOrderingInProgress ? `translateY(-150px)` : 'translateY(0px)',
    height: isSelected ? viewHeight : 0,
    immediate: shouldNotTransition
  })

  const requestSequenceCreation = (toggleOpen) => {
    axios
      .post(`/master_method_sequences/${masterSequence.id}/method_sequences`, {
        method_sequence: {
          project_id: projectId,
          master_method_sequence_id: masterSequence.id
        }
      })
      .then((response) => {
        if (toggleOpen) dispatch({ type: 'UI_TOGGLE', value: true, key: `editingSequenceName--${response.data.data.id}` })
        dispatch({ type: 'ADD_METHOD_SEQUENCE', payload: response.data, projectTradeId: projectTradeId })
        setVisualOptions({ ...visualOptions, checkboxDisabled: false })
      })
      .catch(handleError)
  };

  const requestSequenceUpdate = (_event, fieldValue) => {
    axios
      .patch(`/method_sequences/${existingSequence.id}`, {
        method_sequence: { name: fieldValue }
      })
      .then((response) => {
        dispatch({ type: 'UPDATE_RESOURCE', payload: response.data })
        dispatch({ type: 'CLEAR_TOGGLE_CONTENT', key: sequenceNameKey })
      })
      .catch(handleError)
  }

  const requestCustomSequenceToggleUpdate = (event) => {
    setVisualOptions({ ...visualOptions, checkboxDisabled: true })

    axios
      .patch(`/method_sequences/${existingSequence.id}`, {
        method_sequence: { selected: event.target.checked }
      })
      .then((response) => {
        dispatch({
          type: 'UPDATE_RESOURCE',
          payload: response.data
        })
        setVisualOptions({ ...visualOptions, checkboxDisabled: false })
      })
      .catch(handleError)
  }

  const requestSequenceDeletion = ({ withConfirmation = false } = {}) => {
    axios
      .delete(`/method_sequences/${existingSequence.id}`, {
        params: { confirmation: withConfirmation }
      })
      .then(
        (_resolved) => {
          dispatch({
            type: 'REMOVE_METHOD_SEQUENCE',
            payload: { id: existingSequence.id, type: 'methodSequence' },
            projectTradeId: projectTradeId
          })
          setVisualOptions({ ...visualOptions, confirmationModalOpen: false, checkboxDisabled: false })
        },
        (rejection) => {
          if (rejection.response.status === 412) {
            setVisualOptions({ ...visualOptions, confirmationModalOpen: true })
          } else {
            return Promise.reject(rejection)
          }
        }
      )
      .catch((_error) => {
        setVisualOptions({ ...visualOptions, checkboxDisabled: false, confirmationModalOpen: false })
        handleError()
      })
  }

  const handleError = () => addError(uuidv4(), { fullMessage: genericErrorMessage });

  const handleToggleClick = () => { if (!existingSequence) requestSequenceCreation(true) }

  const handleConfirmationClose = () => {
    setVisualOptions({ ...visualOptions, confirmationModalOpen: false, checkboxDisabled: false })
  }

  const handleMasterSequenceToggle = (event) => {
    setVisualOptions({ ...visualOptions, checkboxDisabled: true })
    event.target.checked ? requestSequenceCreation(false) : requestSequenceDeletion()
  }

  const handleStepSubmit = (_event, fieldValue) => {
    axios
      .post(`/method_sequences/${existingSequence.id}/method_steps`, {
        method_step: { text: fieldValue }
      })
      .then((response) => {
        dispatch({
          type: 'ADD_METHOD_STEP',
          payload: response.data,
          methodSequenceId: existingSequence.id
        })
        dispatch({ type: 'CLEAR_TOGGLE_CONTENT', key: newStepNameKey })
      })
      .catch(handleError)
  }

  const handleSequenceNameChange = (e) => {
    dispatch({
      type: 'EDITING_CONTENT_CHANGE',
      key: sequenceNameKey,
      value: e.target.value
    })
  }

  const handleNewStepTextChange = (e) => {
    dispatch({
      type: 'EDITING_CONTENT_CHANGE',
      key: newStepNameKey,
      value: e.target.value
    })
  }

  return (
    <div className='draggable-row-wrapper'>
      <ToggleableRow
        modifiers={['border-top-none', `${isSelected && !sequenceOrderingInProgress ? 'open' : 'closed'}`, `${sequenceOrderingInProgress ? 'collapsed' : ''}`]}
        isOrdering={sequenceOrderingInProgress}
        checkboxField={
          <CheckboxField
            disabled={visualOptions.checkboxDisabled}
            height={'short'}
            value={sequenceTemplate.id}
            name='sequenceSelected'
            checked={Boolean(isSelected)}
            onChange={masterSequence ? handleMasterSequenceToggle : requestCustomSequenceToggleUpdate}
            labelTextProps={{ className: 'tw-font-medium' }}
          />
        }
        toggleableField={
          <ToggleableField
            autoFocus
            isSubmitDisabled={existingSequence ? false : true}
            isToggled={isEditingSequenceToggle}
            onCancel={() => dispatch({ type: 'CLEAR_TOGGLE_CONTENT', key: sequenceNameKey })}
            onChange={handleSequenceNameChange}
            onSubmit={requestSequenceUpdate}
            onToggle={existingSequence ? (toggleValue) => dispatch({ type: 'UI_TOGGLE', value: toggleValue, key: sequenceNameKey }) : () => {}}
            submitButtonText='Update'
            toggleableItem={
              <div className='toggleable-field__item-wrapper'>
                <span
                  className={`toggleable-row__text tw-border-grey-500 tw-font-medium ${sequenceOrderingInProgress ? 'truncated-text-container' : ''}`}
                  onClick={handleToggleClick}
                >
                  {existingSequence ? existingSequence.attributes.name : sequenceTemplate.attributes.name}
                </span>
              </div>
            }
            value={sequenceFieldText}
          />
        }
      />
      {isSelected && !sequenceOrderingInProgress && (stepIds && stepIds.length > 0) && <div className='header-bar header-bar--border tw-text-s tw-text-grey-900 tw-font-medium tw-tracking-wide tw-border-grey-100 tw-bg-grey-050'>Step</div>}
      <div className={'collapsable-list collapsable-list--nested'} style={{ height: sequenceOrderingInProgress ? '0' : 'auto', overflow: 'hidden' }}>
        <animated.div style={{ transform: springProps.transform, height: springProps.height }}>
          <div className={`collapsable-list__container tw-bg-white tw-border-grey-100${stepIds && stepIds.length > 0 ? ' collapsable-list__container--svelte' : ''}`}>
            <div {...bind}>
              {isSelected &&
                (
                  <React.Fragment>
                    <AvailableStepList
                      methodSequenceId={existingSequence.id}
                      projectTradeId={projectTradeId}
                      containerRef={containerRef}
                    />
                  </React.Fragment>
                )
              }
            </div>
          </div>
        </animated.div>
      </div>

      {
        isSelected && !sequenceOrderingInProgress && (
          <RowBar
            modifiers={['secondary', 'border-top-none', 'enlarged']}
            content={
              <div className='toggleable-row__text-wrapper'>
                <ToggleableField
                  autoFocus
                  isToggled={isAddingStepName}
                  onCancel={() => dispatch({ type: 'CLEAR_TOGGLE_CONTENT', key: newStepNameKey })}
                  onChange={handleNewStepTextChange}
                  onSubmit={handleStepSubmit}
                  onToggle={() => dispatch({ type: 'UI_TOGGLE', value: true, key: newStepNameKey })}
                  placeholderText='Describe step'
                  submitButtonText='Add this step'
                  toggleableItem={
                    <OutlinedButton size='sm' color='grey' onClick={() => {}}>
                      <BranchingSubNode className='m-l--4 m-r-4' width={24} height={24} />
                      <span>Add step</span>
                    </OutlinedButton>
                  }
                  value={newStepText}
                />
              </div>
            }
          />
        )
      }

      {
        visualOptions.confirmationModalOpen && (
          <PortaledModal>
            <ModalBox
              mode='letterbox'
              isOpen={visualOptions.confirmationModalOpen}
              usingStandardDimensions={true}
              onClose={handleConfirmationClose}
              customFooter={
                <div className='modalbox__footer-actions modalbox__footer-actions--right'>
                  <TextButton size='md' color='blue' className='m-r-12' onClick={handleConfirmationClose}>
                    Cancel
                  </TextButton>
                  <FilledButton color='red' onClick={() => { requestSequenceDeletion({ withConfirmation: true }) }}>
                    Yes, remove task
                  </FilledButton>
                </div>
              }
            >
              <React.Fragment>
                <div className='modalbox-header tw-border-grey-100'>
                  <h2 className='modalbox-header__title truncated-text-container tw-text-l tw-text-grey-900 tw-font-semibold'>
                    Are you sure you want to remove this task?
                  </h2>
                </div>
                <div className='modalbox-body'>
                  Removing the task will update the content to the latest version. The current version will be deleted and this action cannot be undone.
                </div>
              </React.Fragment>
            </ModalBox>
          </PortaledModal>
        )
      }
    </div>
  )
}

const PortaledModal = (props) => {
  const modalLocation = document.getElementById('modalRoot')
  return ReactDOM.createPortal(props.children, modalLocation)
}
